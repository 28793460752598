import React from 'react';
import { below } from 'src/components/global/mediaqueries';
import Button from 'src/components/Button';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
    ${below.tablet_s} {
        padding: 40px 12px 20px;
    }
`;

const Info = styled('p')`
    text-align: center;
    margin: 0 auto 24px;
    max-width: 440px;
    line-height: 160%;
`;

const ButtonWrapper = styled('div')`
    text-align: center;
`;

const StyledButton = styled(Button)`
    & + & {
        margin-left: 12px;
    }
`;

const LoginOrRegister = ({setView}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAfterRegister = urlParams.has('after-register');
    const infoText = !isAfterRegister ? 'Continue as guest or register. Already have an account? Sign in.' : 'Please confirm your email address by following the link we\'ve sent to your email';
    return(
        <Wrapper>
            <Info>{infoText}</Info>
            <ButtonWrapper>
                <StyledButton rounded small onClick={() => setView('AccountLogin')}>
                    Sign in
                </StyledButton>
                <StyledButton rounded small outlined onClick={() => setView('AccountRegister')}>
                    Register
                </StyledButton>
            </ButtonWrapper>
        </Wrapper>
    );
};

LoginOrRegister.propTypes = {
    setView: PropTypes.func,
};

LoginOrRegister.defaultProps = {
    setView: null,
};

export default LoginOrRegister;
